<script setup>
    import { ref, computed, watch } from 'vue'
    import { useDiscounts, useCart, useOrder }  from '@apility/ticketing'

    const { total, cart } = useCart()
    const { order } = useOrder()
    const { allDiscounts, selectedDiscount } = useDiscounts()

    const props = defineProps({
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    })

    const show = ref(false)
    const code = ref('')

    const invalid = ref(false)
    const toggle = () => show.value = !show.value

    const checkCode = async () => {
        if (props.disabled || busy.value || !validated.value) {
            return
        }

        busy.value = true

        try {
            const response = await fetch(`/api/discounts/${code.value.toUpperCase()}`)

            if (response.ok) {
                const discount = await response.json()

                if (!cart.value.some(item => discount.products.includes(item.id))) {
                    invalid.value = true
                } else {
                    if (!allDiscounts.value.find(d => d.id === discount.id)) {
                        allDiscounts.value.push(discount)
                    }
    
                    selectedDiscount.value = discount
                }
            } else {
                invalid.value = true
            }
        } finally {
            busy.value = false
        }
    }

    const validated = computed(() => {
        return code.value.length > 0
    })

    const busy = ref(false)

    const visible = computed(() => !selectedDiscount.value && total.value > 0)

    watch(selectedDiscount, () => {
        code.value = ''
        invalid.value = false
    })

    watch(() => cart.value.length, (newLength, oldLength) => {
        if (oldLength && !newLength) {
            selectedDiscount.value = null
        }
    })

    const removeCode = () => {
        code.value = ''
        selectedDiscount.value = null
        invalid.value = false
        show.value = false
    }

    const disabled = computed(() => {
        return selectedDiscount && (selectedDiscount.value?.options?.giftcard ?? false)
    })
</script>

<template>
    <tr v-if="!disabled && visible">
        <td colspan="4">
            <div v-if="!show">
                <a role="button" href="#" @click.prevent="toggle">
                    <strong>Har du en rabattkode?</strong>
                </a> 
            </div>
            <div v-else class="my-3">
                <div class="mb-3">
                    <h6>Legg til rabatt</h6>
                </div>
                <form @submit.prevent="checkCode">
                    <input
                        :autofocus="!code.length"
                        v-model="code"
                        :disabled="props.disabled || busy"
                        class="form-control border border-primary border-2 mb-2 text-uppercase"
                        :class="{ 'is-invalid': invalid }"
                        type="text"
                        placeholder="Rabattkode"
                    />
                    <div class="d-flex">
                        <button
                            :disabled="busy || props.disabled || !validated"
                            type="submit"
                            class="btn btn-primary w-100"
                        >
                            <template v-if="busy">
                                <font-awesome-icon
                                    icon="spinner"
                                    spin
                                />
                            </template>
                            <template v-else>
                                Sjekk kode
                            </template>
                        </button>
                    </div>
                    <div v-if="invalid" class="text-danger mt-2"> Ugyldig rabattkode</div>
                </form>
            </div>
        </td>
    </tr>
    <tr v-if="!disabled && !visible && selectedDiscount">
        <td colspan="4">
            <div>
                <a role="button" href="#" @click.prevent="removeCode">
                    <strong>
                        <font-awesome-icon
                            icon="times"
                            class="me-2"
                        />
                        Fjern rabattkode
                    </strong>
                </a> 
            </div>
        </td>
    </tr>
</template>
