<script setup>
import { onMounted, defineProps, watch, computed } from 'vue'
import { useCartProduct, useOrder, useStorefront, useCart, useDate, useOptions } from '@apility/ticketing'
import QuantityPicker from '@apility/ticketing/src/components/QuantityPicker.vue'

const { date } = useDate()
const { options } = useOptions()

const props = defineProps({
    product: {
        type: Object,
        required: true,
    }
})

const { storefront } = useStorefront()

const products = computed({
    get: () => storefront.value?.products,
    set: (value) => {
        storefront.value.products = value
    }
})

const { order } = useOrder()
const cartItem = computed(() => order.value?.cart.find(item => item.id === props.product.id))
const { cart, availableCapacityExceptFor } = useCart()

const minPrice = computed(() => props.product.options?.minAmount ?? 0)
const maxPrice = computed(() => props.product.options?.maxAmount ?? 10000)

const price = computed({
    get() {
        return props.product.price
    },
    set(value) {
        const item = products.value.find(product => product.id === props.product.id)

        if (item) {
            item.price = value
        }
    }
})

const maxSelectable = computed(() => Math.min(props.product.options?.maximumQuantity ?? 99, options.value?.maxQuantityPerProduct ?? 99))
const maxCapacity = availableCapacityExceptFor(props.product.id)
const max = computed(() => Math.min(Math.floor(maxCapacity.value / props.product.capacity), maxSelectable.value))
const { quantity, canIncrement, canDecrement } = useCartProduct(props.product.id, max)
const limitQuantityToMax = () => {
    if (quantity.value > max.value) {
        quantity.value = max.value
    }
}

const canIncrementCustomGiftcardItem = computed(() => {
    if (price.value && price.value < minPrice.value) {
        return false
    }

    return canIncrement.value
})

watch(date, limitQuantityToMax, { immediate: true })
watch(quantity, (quantity) => {
    limitQuantityToMax()
    if (quantity > 0 && price.value < minPrice.value) {
        price.value = minPrice.value
    }
}, { immediate: true })

watch(price, (value) => {
    if (value && !quantity.value && value >= minPrice.value) {
        quantity.value = 1
    }
    if ((!value || value < minPrice.value) && quantity.value) {
        quantity.value = 0
    }

    cart.value = [
        ...cart.value.map(item => {
            if (item.id === props.product.id) {
                return {
                    ...item,
                    total: value * quantity.value,
                    options: {
                        ...item.options ?? {},
                        price: value
                    }
                }
            }

            return item
        })
    ]
}, { immediate: true })

watch(() => cartItem.value?.options?.price ?? false, (value) => {
    if (value !== false) {
        price.value = value
    }
}, { immediate: true })

window.cart = cart
</script>

<template>
    <storefront-widget class="storefront__product">
        <slot :product="props.product" :quantity="quantity" :max="max" :can-increment="canIncrement"
            :can-decrement="canDecrement">
            <div class="storefront__product__details">
                <div class="storefront__product__details__name">
                    <slot name="name" :product="props.product" :name="props.product.name">
                        <strong>
                            {{ props.product.name }}
                        </strong>
                    </slot>
                </div>
                <slot v-if="props.product.description" name="description" :product="props.product"
                    :description="props.product.description">
                    <i class="storefront__product__details__description">
                        {{ props.product.description }}
                    </i>
                </slot>
                <div class="storefront__product__details__price w-100">
                    <div class="d-flex align-items-center w-100">
                        <span class="me-1">kr</span>
                        <input ref="priceInput" type="number" v-model="price" placeholder="Tast inn beløp" :step="50"
                            :max="maxPrice" :min="minPrice" class="d-inline-block border-0 w-100" />
                    </div>

                    <div v-if="price > 0 && price < minPrice" class="text-danger">
                        Minstebeløp {{ minPrice }}
                    </div>
                </div>
            </div>
            <div class="storefront__product__quantity">
                <slot name="quantity-picker" :quantity="quantity" :max="max"
                    :can-increment="canIncrementCustomGiftcardItem" :can-decrement="canDecrement">
                    <QuantityPicker v-model="quantity" :max="max" :can-increment="canIncrementCustomGiftcardItem"
                        :can-decrement="canDecrement" />
                </slot>
            </div>
        </slot>
    </storefront-widget>
</template>