<script setup>
    import { defineProps, provide, ref, computed } from 'vue'
    import { parse, endOfYear, format } from 'date-fns'
    
    const props = defineProps({
        order: {
            type: Object,
            required: true
        },

        today: {
            type: String,
            required: true
        },

        dateRule: {
            type: Object,
            required: true,
        },

        fetchEndpoint: {
            type: String,
            required: true
        },

        updateEndpoint: {
            type: String,
            required: true
        }
    })

    const storefront = ref({
        endpoints: {
            fetchStorefront: props.fetchEndpoint,
        }
    })

    const minimumDate = computed(() => props.today)
    const maximumDate = computed(() => format(endOfYear(parse(minimumDate.value, 'yyyy-MM-dd', new Date)), 'yyyy-MM-dd'))

    const config = computed(() => ({
        locale: 'nb_NO',
        minimumDate: minimumDate.value,
        maximumDate: maximumDate.value,
        dateRules: props.dateRule,
    }))

    const order = ref(props.order)
    const date = ref(order.value.data.date)
    
    const modified = computed(() => !success.value || date.value !== order.value.data.date)

    provide('date', computed({
        get: () => parse(date.value, 'yyyy-MM-dd', new Date()),
        set: (value) => date.value = format(value, 'yyyy-MM-dd')
    }))

    provide('storefront', storefront)
    provide('config', config)
    provide('locale', computed(() => config.value.locale))
    provide('order', order)
    provide('cart', ref([]))

    const busy = ref(false)
    const error = ref(false)
    const success = ref(false)

    const updateOrder = async () => {
        busy.value = true
        error.value = false
        success.value = false

        const response = await fetch(props.updateEndpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                date: date.value
            })
        })

        if (!response.ok) {
            busy.value = false
            error.value = true
            success.value = false
            return
        }

        busy.value = false
        success.value = true
    }
</script>

<template>
    <div class="storefront-editor">
        <storefront-datepicker>
            <template #header></template>
        </storefront-datepicker>

        <button
            class="btn btn-primary-embossed btn-lg w-100"
            @click="updateOrder"
            :disabled="busy || !modified"
        >
            <template v-if="!busy">
                Endre dato
            </template>
            <template v-else>
                <font-awesome-icon icon="spinner" spin />
            </template>
        </button>

        <div class="alert alert-success mt-3" v-if="success">
            Datoen ble endret
        </div>

        <div class="alert alert-danger mt-3" v-if="error">
            Noe gikk galt
        </div>
    </div>
</template>

<style lang="scss">
    .storefront-editor {
        .storefront__widget {
            box-shadow: none!important;
            border-color: transparent!important;
        }
    }
</style>