import MessageChannel from '@apility/message-channel'

document.addEventListener('DOMContentLoaded', async () => {
    const root = document.getElementById('mypage')

    if (root) {
        const userId = root.dataset.userId
        const channel = root.dataset.channel
        const topic = `user.${userId}`

        const messageChannel = await MessageChannel.connect(channel, topic)

        const refresh = () => {
            window.location.reload()
        }

        messageChannel.on('giftcard.created', refresh)
        messageChannel.on('season-ticket.created', refresh)
    }
})