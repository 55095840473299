<script setup>
    import { ref, computed, watch } from 'vue'
    import { useStorefront, useOrder,useCart, useProducts, useDiscounts } from '@apility/ticketing'

    const { storefront } = useStorefront()
    const { order } = useOrder()
    const { cart } = useCart()
    const { giftcards, discount } = useDiscounts()
    const { products } = useProducts()

    const STATE_ENTER_CODE = 0
    const STATE_CHECKING_CODE = 1
    const STATE_INVALID = 3
    const STATE_VALID = 4
    const STATE_DONE = 5

    const state = ref(STATE_ENTER_CODE)
    const code = ref('')
    const giftcard = ref(null)
    const message = ref('')

    const canPayWithGiftcard = computed(() => {
        if (state.value === STATE_DONE) {
            return false
        }

        return cart.value.length && cart.value.reduce((enabled, item) => {
            const product = products.value.find(product => product.id === item.id)

            if (product) {
                return enabled && product.options.canBePurchasedWithGiftcard
            }

            return enabled
        }, true)
    })

    const disabled = computed(() => {
        switch(state.value) {
            case STATE_CHECKING_CODE:
                return true
            default:
                return false
        }
    })

    const showForm = computed(() => {
        return state.value != STATE_VALID
    })

    const checkCode = async () => {
        giftcard.value = null
        state.value = STATE_CHECKING_CODE

        try {
            const response = await fetch(`/api/giftcards/${code.value.toUpperCase()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

            const data = await response.json()
            
            if (response.status < 200 || response.status >= 300) {
                if (data.message) {
                    message.value = data.message
                }

                throw new Error('Invalid giftcard')
            }

            giftcard.value = data
            state.value = STATE_VALID

            applyGiftcard(giftcard.value)

        } catch (e) {
            state.value = STATE_INVALID
        }
    }

    const cancel = () => {
        giftcard.value = null
        state.value = STATE_ENTER_CODE
    }

    const applyGiftcard = (giftcard) => {
        giftcards.value = [
            ...giftcards.value
                .filter(item => item.id !== giftcard.id),
            giftcard
        ]

        discount.value = giftcard.code

        /* state.value = STATE_DONE */
    }

    watch(discount, (newState, oldState) => {
        if (oldState && !newState) {
            state.value = STATE_ENTER_CODE
        }
    })

    if (order.value.discount && storefront.value.discounts.length) {
        const giftcard = storefront.value.discounts.find(discount => discount.id === order.value.discount && discount.options.giftcard && discount.type === 'fixedAmount')

        if (giftcard) {
            applyGiftcard(giftcard)
        }
    }
</script>

<template>
    <storefront-widget v-if="canPayWithGiftcard">
        <div class="mb-3">
            <h5>Betale med gavekort?</h5>
        </div>

        <template v-if="showForm">
            <form @submit.prevent="checkCode">
                <input
                    v-model="code"
                    class="form-control border border-primary border-2 mb-3"
                    type="text"
                    :disabled="disabled"
                    placeholder="Gavekortkode"
                />

                <button
                    type="submit"
                    :disabled="disabled || !code.length"
                    class="btn btn-primary-embossed w-100 btn-lg"
                >
                    <template v-if="state !== STATE_CHECKING_CODE">
                        Sjekk
                    </template>
                    <template v-else>
                        <font-awesome-icon
                            icon="spinner"
                            spin
                        />
                    </template>
                </button>
            </form>

            <div
                v-if="state === STATE_INVALID"
                class="text-danger mt-2"
            >
                <template v-if="message && message.length">
                    {{ message }}
                </template>
                <template v-else>
                    Ugyldig gavekortkode
                </template>
            </div>
        </template>

        <template v-if="state === STATE_VALID">
            <div class="h4 text-success">
                Gavekortet er gyldig!
            </div>
            <div class="text-success">
                Tilgjengelig beløp:
                <strong>
                    <currency-string :value="giftcard.value" />
                </strong>
            </div>

            <div class="d-flex mt-3">
                <!-- <button
                    class="btn btn-primary-embossed px-4 w-50 me-3"
                    @click="applyGiftcard(giftcard)"
                >
                    Bruk gavekort
                </button> -->
                <!-- <button
                    @click="cancel"
                    class="btn btn-danger-embossed ms-auto px-4 w-100 ms-3"
                >
                    Avbryt
                </button> -->
            </div>
        </template>
    </storefront-widget>
</template>