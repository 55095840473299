import { createApp } from 'vue'

import Ticketing from '@apility/ticketing'
import OrderEditor from './components/OrderEditor'
import FontAwesomeIcon from './components/FontawesomeIcon'

document.querySelectorAll('.order-edit')
    .forEach(container => createApp()
        .component('OrderEditor', OrderEditor)
        .component('FontAwesomeIcon', FontAwesomeIcon)
        .use(Ticketing)
        .mount(container)
    )
