<template>
    <div
        tabindex="-1"
        class="offcanvas"
        :class="offcanvasClassList"
    >
        <div class="offcanvas-header">
            <slot name="title"></slot>
            <button
                type="button"
                class="btn-close text-reset"
                aria-label="Close"
                @click="hide"
            ></button>
        </div>
        <div class="offcanvas-body">
            <slot
                :toggle="toggle"
                :show="show"
                :hide="hide"
            ></slot>
        </div>
    </div>
    <div
        v-if="props.show"
        class="offcanvas-backdrop fade show"
    ></div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },

    position: {
        type: String,
        default: 'end',
    },

    fade: {
        type: Boolean,
        default: true,
    }
})

const emit = defineEmits(['update:show'])

const toggle = () => emit('update:show', !props.show)
const show = () => emit('update:show', true)
const hide =() => emit('update:show', false)

const offcanvasClassList = computed(() => ({
    'show': props.show,
    [`offcanvas-${props.position}`]: true,
}))
</script>