import { createApp } from 'vue'

import VueDebounce from 'vue-debounce'

import Ticketing from '@apility/ticketing'

import FontAwesomeIcon from './components/FontawesomeIcon'
import OffcanvasWidget from './components/OffcanvasWidget'
import GiftcardPaymentWidget from './components/GiftcardPaymentWidget'
import CustomGiftcardProductItem from './components/CustomGiftcardProductItem'
import DiscountWidget from './components/DiscountWidget'
import GtagManager from './components/GtagManager'

document.querySelectorAll('.ticketing')
    .forEach(container => createApp({
        data: () => ({ showCheckout: false })
    })
        .component('FontAwesomeIcon', FontAwesomeIcon)
        .component('OffcanvasWidget', OffcanvasWidget)
        .component('GiftcardPaymentWidget', GiftcardPaymentWidget)
        .component('CustomGiftcardProductItem', CustomGiftcardProductItem)
        .component('DiscountWidget', DiscountWidget)
        .component('GtagManager', GtagManager)
        .use(VueDebounce, { listenTo: 'input' })
        .use(Ticketing)
        .mount(container)
    )
