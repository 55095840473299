<script setup>
    import { ref, defineProps, computed } from 'vue'
    import { FormField } from '@apility/ticketing'

    const props = defineProps({
        endpoint: {
            type: String,
            required: true,
        },
        method: {
            type: String,
            default: 'POST',
        },
        form: {
            type: Object,
            required: true,
        },
    })

    const busy = ref(false)

    const fields = computed(() => {
        return props.form?.fields ?? []
    })

    const errorMessage = ref(null)

    const formElement = ref(null)
    
    const errors = ref({})
    const payload = ref({})

    const submitForm = async () => {
        busy.value = true
        errorMessage.value = null

        try {
            const response = await fetch(props.endpoint, {
                method: props.method,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(payload.value),
            })
    
            const data = await response.json()
    
            if (response.status < 200 || response.status >= 300) {
                throw new Error('Det oppstod en feil')
            }
    
            busy.value = false
            
            setTimeout(() => {
                window.location.href = data.next
            })
        } catch (error) {
            busy.value = false
            errorMessage.value = error.message
        }
    }
    
    const submit = async () => {
        if (reportValidity()) {
            try {
                await submitForm()
            } catch (error) {
                errors.value = error.response?.data?.errors ?? {}
            }
        }
    }

    const reportValidity = () => {
        if (!formElement.value.checkValidity()) {
            formElement.value.reportValidity()
            return false
        }
        return true
    }
</script>

<template>
    <form
        class="form"
        ref="formElement"
        @submit.prevent="submit"
    >
        <FormField
            v-for="field in fields"
            :key="field.name"
            :name="field.name"
            :label="field.label"
            :type="field.type"
            :placeholder="field.placeholder"
            :options="field.options"
            :required="field.required"
            :has-errors="Boolean(errors[field.name] ?? false)"
            :disabled="busy"
            v-model="payload[field.name]"
        />
        <div v-if="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
        </div>
        <div class="storefront__checkout-form__slot">
            <slot :busy="busy"></slot>
        </div>
    </form>
</template>